import * as React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/seo';

const UnsubscribeConfirmation = ({location}) => { 
    return (
        <article id="unsubscribeconfirmation" className="page-content">
            <SEO title="Unsubscribe Confirmation - Mike" url={location.pathname} /> 
            <header className="text-center">
                <h1>Confirmation</h1>
            </header>
                
            <div className="page-content__body">            
                <div className="page-content__inner">   
                    <br />
                    <p className="text-center">You have been unsubscribed!</p>
                    <br />
                    <p className="text-center">
                        <Link to="/">Return to homepage</Link>
                    </p>
                    <br />
                    <br />
                </div>
            </div>
        </article>  
    );
};

export default UnsubscribeConfirmation;